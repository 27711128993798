<template>
  <material-card-sba
    :size.sync="internalSize"
    :order.sync="internalOrder"
    :height.sync="internalHeight"
    :maxOrderValue="maxOrderValue"
    color="accent"
    :widgetIcon="widgetIcon"
    :title="
      $t('components.vue_material_dashboard.items.hsa.title', [
        hsaLabel
          ? $vuetify.lang.current === 'fr'
            ? hsaLabel?.Fr
            : hsaLabel?.En
          : 'HSA',
      ])
    "
  >
    <v-row
      no-gutters
      class="mx-2 mt-4"
      justify="space-around"
      v-if="HSAExpiryDate"
    >
      <v-col cols="12" class="mt-6" v-for="i in [0, 1]" :key="'hsa-' + i">
        <v-col class="pa-0 title1 text-uppercase">{{
          hsaData?.Credits[i].Year +
          " " +
          $t("components.vue_material_dashboard.items.hsa.credit")
        }}</v-col>
        <v-col class="pa-0 text-h6 font-weight-bold accent--text"
          >{{ $helpers.toCurrency(hsaData?.Credits[i].Amount) || "--" }}
        </v-col>

        <v-col class="pa-0 title1 text-uppercase">{{
          $t("components.vue_material_dashboard.items.hsa.expiration_date")
        }}</v-col>
        <v-col class="pa-0 text-h6 font-weight-bold info--text"
          >{{ $helpers.formatDate(getHsaExpiryDate(hsaData?.Credits[i])) }}
        </v-col>
      </v-col>
      <v-btn color="accent" class="action-btn" :to="'hsa-balance'">{{
        $t("globals.more")
      }}</v-btn>
      <v-progress-circular
        v-show="loading"
        size="50"
        class="loader"
        color="primary"
        indeterminate
      ></v-progress-circular>
    </v-row>

    <v-row no-gutters class="fill-height mx-2" justify="space-around" v-else>
      <v-col cols="12" class="mt-6">
        <v-col class="pa-0 title1 text-uppercase">{{
          $t("components.vue_material_dashboard.items.hsa.remaining")
        }}</v-col>

        <v-col class="pa-0 text-h6 font-weight-bold accent--text"
          >{{ $helpers.toCurrency(hsaData?.CurrentBalance) || "--" }}
        </v-col>
      </v-col>
      <v-col cols="12">
        <v-col class="pa-0 title1 text-uppercase">{{
          $t("components.vue_material_dashboard.items.hsa.upcoming_credits")
        }}</v-col>
        <v-col class="pa-0 text-h6 font-weight-bold accent--text"
          >{{ $helpers.toCurrency(hsaData?.HSACreditToExpire?.Amount) || "--" }}
        </v-col>
      </v-col>
      <v-col cols="12" class="mb-12">
        <v-col class="pa-0 title1 text-uppercase">{{
          $t("components.vue_material_dashboard.items.hsa.expiration_date")
        }}</v-col>
        <v-col class="pa-0 text-h6 font-weight-bold info--text mb-12"
          >{{
            $helpers.formatDate(hsaData?.HSACreditToExpire?.ExpirationDate) ||
            "--"
          }}
        </v-col>
      </v-col>
      <v-btn color="accent" class="action-btn" :to="'hsa-balance'">{{
        $t("globals.more")
      }}</v-btn>
      <v-progress-circular
        v-show="loading"
        size="50"
        class="loader"
        color="primary"
        indeterminate
      ></v-progress-circular>
    </v-row>
  </material-card-sba>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex";
import MaterialCardSba from "@/components/VueMaterialDashboard/material/MaterialCardSbaDashboard.vue";
export default {
  components: {
    MaterialCardSba,
  },
  props: {
    widgetId: String,
    widgetIcon: String,
    size: Number,
    height: Number,
    order: Number,
    maxOrderValue: Number,
  },
  data() {
    return {
      internalSize: undefined,
      internalOrder: undefined,
      internalHeight: undefined,
      loading: false,
    };
  },
  computed: {
    ...mapGetters("auth", ["user", "role"]),
    ...mapGetters("common", ["userProfile", "hsaLabel", "HSAExpiryDate"]),
    ...mapState("data", ["hsa"]),
    hsaData() {
      return (this.hsa && this.hsa[0]) || {};
    },
  },
  watch: {
    size: {
      handler(v) {
        this.internalSize = v;
      },
    },
    internalSize: {
      handler(v) {
        this.$emit("update:size", v);
      },
    },
    height: {
      handler(v) {
        this.internalHeight = v;
      },
    },
    internalHeight: {
      handler(v) {
        this.$emit("update:height", v);
      },
    },
    order: {
      handler(v) {
        this.internalOrder = v;
      },
    },
    internalOrder: {
      handler(v) {
        this.$emit("update-order", v);
      },
    },
  },
  mounted() {
    if (this.size !== null && this.size !== undefined) {
      this.internalSize = this.size;
    }
    if (this.height !== null && this.height !== undefined) {
      this.internalHeight = this.height;
    }
    if (this.order !== null && this.order !== undefined) {
      this.internalOrder = this.order;
    }
  },
  methods: {
    ...mapActions("data", ["getHSABalances"]),
    getHsaExpiryDate(item) {
      if (typeof this.HSAExpiryDate === "string") return this.HSAExpiryDate;
      const year = item?.Year;
      const memberGroup = this.userProfile?.MemberGroup?.Name;
      const memberGroupDate = this.HSAExpiryDate.MemberGroups.find((group) =>
        group.Names.includes(memberGroup)
      );
      return memberGroupDate?.[year] || this.HSAExpiryDate[year];
    },
  },
};
</script>
<style scoped>
.lh-1 {
  line-height: 1.1rem !important;
}
.title1 {
  font-size: 12px;
  font-weight: 600;
}
.loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.action-btn {
  position: absolute;
  bottom: 24px;
  right: 24px;
}
</style>
